import { getFnoName, ILiveMockConfig, LiveMockService, mapToNull, NavigationService } from '@aex/ngx-toolbox';
import { ConfigApi, QuestionsApi } from '@aex/shared/apis';
import { AuthType, DEFAULT_CONFIG, ENVIRONMENT_CONFIG_TOKEN, IEnvFile, IEnvironment, IGlobalConfig, IInterestQuestionsWrapper, ParamMetaData, ROUTE_CONFIG, SERVER_CONFIG } from '@aex/shared/common-lib';
import { AppConfigLoaderService, ConfigService } from '@aex/shared/root-services';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { merge } from 'lodash';
import { defer, Observable } from 'rxjs';
import { catchError, finalize, switchMap, tap } from 'rxjs/operators';
import { ClientInterfaceKnoxActionHelper } from '../../../helpers/client-interface-knox-action-helpers';

import {RegisterKnoxActionsService} from "@aex/security/shared";

@Injectable()
export class ClientInterfaceAppConfigLoaderService extends AppConfigLoaderService {

	constructor(
		http: HttpClient,
		@Inject(ENVIRONMENT_CONFIG_TOKEN) environment: IEnvironment,
		private readonly navigationService: NavigationService,
		private readonly liveMockService: LiveMockService,
		private readonly clientInterfaceConfigService: ConfigService,
		private readonly registerKnoxActionsService: RegisterKnoxActionsService,
	) {
		super(http, environment);
	}

	protected override loadServerUrls() {
		SERVER_CONFIG.supportUrl = this.environment.supportUrl;
		SERVER_CONFIG.paymentServerUrl = this.environment.paymentServerUrl;
		SERVER_CONFIG.purchaseOrderServerUrl = this.environment.purchaseOrderServerUrl;
		SERVER_CONFIG.wifiServerUrl = this.environment.wifiServerUrl;
		SERVER_CONFIG.securityServiceUrl = this.environment.securityServiceUrl;
		// SERVER_CONFIG.serverUrl = this.clientInterfaceConfigService.startUpData.isIsp ? this.environment.ispServerUrl : this.environment.fnoServerUrl;
		// Force to fno-interface to be called via isp server url
		SERVER_CONFIG.serverUrl = this.environment.ispServerUrl;
		SERVER_CONFIG.proxyServerUrl = this.environment.ispServerUrl;
		SERVER_CONFIG.portalUrl = this.clientInterfaceConfigService.startUpData.fnoPortalBaseUrl;
		SERVER_CONFIG.fusionSolarServiceUrl = this.environment.fusionSolarServerUrl;
		SERVER_CONFIG.weatherServerUrl = this.environment.weatherServerUrl;
		SERVER_CONFIG.loadSheddingServerUrl = this.environment.loadSheddingServerUrl;
		SERVER_CONFIG.pushNotificationsServerUrl = this.environment.pushNotificationsServerUrl;
		SERVER_CONFIG.kmlServerUrl = this.environment.kmlServerUrl;
		SERVER_CONFIG.knoxUrl = this.environment.knoxUrl;
		SERVER_CONFIG.knoxEnabled = this.environment.knoxEnabled;
	}

	public override loadAppConfig(): Observable<void> {
		this.navigationService.startLoading();
		return this.http.get<IEnvFile>(ConfigApi.dynamicEnvironment, {
			params: new ParamMetaData({ authToken: AuthType.NONE }),
		}).pipe(
			switchMap((envConfig: IEnvFile) => {
				this.clientInterfaceConfigService.fnoName = getFnoName(this.environment, envConfig.operatorResolution);
				if (this.clientInterfaceConfigService.fnoName)
					merge(this.environment, { operator: this.clientInterfaceConfigService.fnoName });

				merge(
					this.environment,
					envConfig.environments[this.clientInterfaceConfigService.getConfigName()],
					this.environment.override,
				);
				this.clientInterfaceConfigService.operatorBehaviorSubject.next(this.clientInterfaceConfigService.operator);
				return this.clientInterfaceConfigService.getFnoConfig(this.clientInterfaceConfigService.operator, this.clientInterfaceConfigService.getConfigName());
			}),
			tap(fnoConfig => {
				// load global config then override by environment
				this.clientInterfaceConfigService.startUpData = { ...fnoConfig, ...fnoConfig[this.clientInterfaceConfigService.getConfigName()], ...this.environment.override };
				merge(this.environment, this.clientInterfaceConfigService.startUpData.environmentOverride)
				this.clientInterfaceConfigService.configValues = this.clientInterfaceConfigService.startUpData;
				this.initLoadAppConfig();
				ROUTE_CONFIG.startUrl = this.clientInterfaceConfigService.startUpData.startUrl ?? 'landing';
				// load server urls
				this.loadServerUrls();
				this.liveMockService.config = this.getLiveMockServiceConfig();

        // Register Knox Actions
        this.registerKnoxActionsService.registerKnoxActions(
          ClientInterfaceKnoxActionHelper.getSystemName(),
          ClientInterfaceKnoxActionHelper.getSystemActions()
        ).subscribe();

			}),
			catchError(() => this.clientInterfaceConfigService.getFnoConfig(DEFAULT_CONFIG, this.clientInterfaceConfigService.getConfigName())),
			mapToNull(),
			finalize(() => defer(() => this.navigationService.stopLoading())),
		);
	}

	protected override initLoadAppConfig(): void {
		this.headerFooterConfig(this.clientInterfaceConfigService.configValues);
		this.loadQuestionsWrapper().subscribe();
	}

	public override getLiveMockServiceConfig(): ILiveMockConfig {
		return {
			enabled: this.environment.mocksEnabled,
			serverUrls: [
				this.environment.ispServerUrl,
				this.environment.fnoServerUrl,
				this.environment.securityServiceUrl,
				this.environment.paymentServerUrl,
				this.environment.purchaseOrderServerUrl,
				this.environment.fusionSolarServerUrl,
				this.environment.loadSheddingServerUrl,
				this.environment.pushNotificationsServerUrl,
				this.environment.weatherServerUrl,
				this.environment.kmlServerUrl,
			],
			portNumbers: [],
		};
	}

	public headerFooterConfig(configValues: IGlobalConfig): void {
		this.clientInterfaceConfigService.configBehaviorSubject.next({
			displayOrderCard: configValues.displayOrderCard,
			displayPortalFooter: configValues.displayPortalFooter,
			showNewLook: configValues.showNewLook,
			isTablet: window.innerWidth <= this.clientInterfaceConfigService.breakpointTablet,
		});
	}

	public loadQuestionsWrapper(): Observable<IInterestQuestionsWrapper> {
		return this.http.get<IInterestQuestionsWrapper>(QuestionsApi.questionsPath(this.clientInterfaceConfigService.operator), {
			params: new ParamMetaData({authToken: AuthType.NONE}),
		}).pipe(
				catchError(() => this.http.get<IInterestQuestionsWrapper>(QuestionsApi.questionsPath('default'), {
					params: new ParamMetaData({authToken: AuthType.NONE}),
				})),
				tap(({questions}) => this.clientInterfaceConfigService.questionList = questions),
		);
	}
}
