<footer [hidden]="hideFooter">
    <ng-container *ngIf="configService.configStream | async as config">
        <div *ngIf="config.displayPortalFooter === 'layout1' && !config.isTablet; else layout2" id="layout1"
            class="contact footer-container">
            <div fxLayout="row" fxLayoutAlign="space-evenly center">
                <div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="15px">
                    <strong class="text-center type20">Our Address</strong>
                    <div class="text-center">
                        <div [innerHTML]="footerAddress">
                        </div>
                        Phone: <strong><a href="tel:{{phone}}">{{phone}}</a></strong>
                        <br>
                        Email: <strong><a href="mailto:{{email}}">{{email}}</a></strong>
                    </div>
                </div>
                <div class="footer-logo-container footer-layout-1">
                    <img ngSrc="assets/img/{{imgLogo}}" [fill]="true" [priority]="true" class="logo {{footerImgClass}}"
                        alt="{{imgAlt}}">
                </div>
            </div>
            <div [innerHTML]="footerlogoText" class="footer-logo-text"></div>
            <p class="footer-terms">{{ terms }} {{ currentYear }}</p>
        </div>

        <ng-template #layout2>
            <div *ngIf="config.displayPortalFooter === 'layout2' && !config.isTablet; else layout3"
                class="contact footer-container">
                <div class="footer-logo-container footer-layout2" fxLayout="column" fxLayoutAlign="center center">
                <img ngSrc="assets/img/{{imgLogo}}" [fill]="true" [priority]="true" class="logo {{footerImgClass}}"
                    alt="{{imgAlt}}">
                </div>
                <p class="footer-terms">{{ terms }} {{ currentYear }}</p>
            </div>
        </ng-template>
        <ng-template #layout3>
            <div *ngIf="config.displayPortalFooter === 'layout3' && !config.isTablet; else normal"
                class="contact footer-container">
                <div class="footer-logo-container" fxLayout="column" fxLayoutAlign="center center">
                <img ngSrc="assets/img/{{imgLogo}}" [fill]="true" [priority]="true" class="logo {{footerImgClass}}"
                    alt="{{imgAlt}}">
                </div>
                <div [innerHTML]="footerlogoText" class="footer-logo-text"></div>
                <p class="footer-terms">{{ terms }} {{ currentYear }}</p>
            </div>
        </ng-template>

        <ng-template #normal>
            <div class="container-grid" *ngIf="!config.showNewLook; else newLookFooter">
                <div fxLayoutAlign="end center" fxLayout="row" fxLayoutGap="45px" class="rounded-16 p-3 contact">
                    <div fxFlex="40" class="text-left">
                        <strong class="text-center type24 uppercase">Contact Us</strong>
                    </div>
                    <div fxFlex="60" class="my-auto">
                        <p class="text-left my-auto">
                            Email: <strong><a href="mailto:{{ email }}">{{ email }}</a></strong>
                            <br>
                            Phone: <strong><a href="tel:{{ phone }}">{{ phone }}</a></strong>
                        </p>
                    </div>
                </div>
                <p class="terms">{{ terms }} {{ currentYear }}</p>
            </div>
        </ng-template>

        <ng-template #newLookFooter>
            <div class="container-fluid bg-header" fxLayout="row" fxLayoutAlign="center center">
                <div fxLayout="row" fxLayoutAlign="center center">
                    <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="30px" fxLayoutGap.gt-md="75px">
                        <h3 class="uppercase text-white no-wrap mb-0 text-lg md:text-2xl">Contact Us</h3>
                        <div fxLayout="column" fxLayout.gt-md="row" fxLayoutGap="5px" fxLayoutGap.gt-md="50px">
                            <div *ngFor="let x of configService?.configValues?.contactDetails">
                                <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px" class="h-100">
                                    <em *ngIf="x.icon === 'whatsapp'" class="pi pi-whatsapp text-base md:text-lg"></em>
                                    <em *ngIf="x.icon === 'email'" class="pi pi-envelope text-base md:text-lg"></em>
                                    <em *ngIf="x.icon === 'contact'" class="pi pi-phone text-base md:text-lg"></em>
                                    <h5 class="text-white mb-0 no-wrap text-base md:text-lg">{{x.content}}</h5>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </ng-template>
    </ng-container>
</footer>
