import { LatLngLiteral } from '@agm/core/services/google-maps-types';
import { Guid } from 'guid-typescript';
import { Observable } from 'rxjs';
import { TSMap } from 'typescript-map';
import {
	AllowedDaysType,
	BillingStatus,
	CollectionDayAdjustmentPreference,
	CollectionDayChangeFutureDatedPurchaseBehaviour,
	ConfigurationMappingType,
	ConfigurationType,
	FileUploadStatus,
	OnActivationPurchaseCreatedFor,
	PaymentType,
	ProductPriceType,
	PurchaseStatusCalculate,
	ServiceStatus,
	ServiceStatusCategory,
	ServiceType,
} from './enums';
import { IPaymentResponse } from './interfaces/payment';
import { IWorkOrder } from './service';
import { FormGroup } from '@angular/forms';
import { IProductPricing } from './product';

export interface INotification {
	id: string;
	subject: string;
	body: string;
	recipient_id: string;
	created_at: string;
	processed: boolean;
	kicked_off: boolean;
	dismissed: boolean;
}

export interface INotificationsList {
	id: string;
	type: {
		id: string;
		name: string;
		application_slug: string;
		is_predefined: boolean;
		tags: string[];
		properties: string[];
	};
	requested_at: string;
	provider_type: number;
	tags: string;
	recipients: string;
	last_attempt_at: string;
	notification_status: number;
	error_code: number;
	error_message: string;
}

export interface IProvider {
	id: string;
	parent_id?: string;
	name: string;
	registration_number?: string;
	support_number?: string;
	website?: string;
	email?: string;
	active?: boolean;
	enabled?: boolean;
}

export interface IOperator {
	id: number;
	name: string;
	guid: string;
}

export interface INetworkState {
	id: number;
	name: string;
	type: string;
	updated_at: string;
	created_at: string;
	vendor_code: string;
}

export interface IDeviceInformation {
	FN: string;
	SN: string;
	PN: string;
	ONTID: string;
	NAME: string;
	ALIAS: string;
	VENDORID: string;
	EQUIPMENTID: string;
	MAINSOFTVERSION: string;
	SERIALNUM: string;
	LSTUPTIME: string;
	LSTDOWNTIME: string;
	DEVCURRENTTIME: string;
}

export interface ITriageConfig {
	id: string;
	external_system_id: string;
	data: string;
	owner_id: string;
}

export interface ITriageInstance {
	id: string;
	triage_config_id: string;
	user_id: string;
	start_date: string;
	service_status: string;
	is_instance_complete: boolean;
}

export interface ITriageSnapshot {
	id: string;
	triage_instance_id: string;
	created_date: string;
	results: string;
	meta_data: string;
}

export interface ISupportHistoryInstance {
	created: string;
	device_status_start: string;
	progress_status: string;
	updated: string;
	reference: string | number;
	triage_results: string;
	resulting_wo: IWorkOrder;
}

export interface IWifi {
	Username: string;
	Password: string;
}

export interface IBank {
	country_code: string;
	name: string;
	bank_code: string;
}

export interface ICreditPurchase {
	service_id?: string;
	product_id?: string;
	product_type?: ProductPriceType;
	period?: number;
}

export interface ILineItem {
	description: string;
	total_price: number;
	total_price_inc_tax: number;
	price_per_quantity: number;
	price_per_quantity_inc_tax: number;
	quantity: number;
	once_off: boolean;
	tax_rate: number;
	tax_amount: number;
	net_amount_excl: number;
	amount_per_quantity: number;
	total_amount: number;
	selected?: boolean;
}

export interface IPricing {
	medium_type: string;
	transaction_fee?: number;
	amount: number;
	amount_inc_tax: number;
	quantity: number;
	lines: ILineItem[];
}

export interface IPricingInfo {
	manual_payment_allowed: boolean;
	online_from?: Date;
	is_overdue: boolean;
	is_last_payment_pending: boolean;
	period_end_date?: Date;
	service_type: ServiceType;
	pricing_info_list: IPricingMediumInfo[];
}

export interface IBuyNowRequest {
	service_id: Guid;
	purchase_lines: IPurchaseLine[];
	customer_statement_ref: string;
	purchase_date: Date;
	purchase_source_id?: Guid;
	comment?: string;
}

export interface IPurchaseLine {
	id?: Guid;
	purchase_id?: Guid;
	original_purchase_line_id?: Guid;
	purchase_line_type_id: number;
	description: string;
	total_amount: number;
	amount_per_quantity_incl: number;
	amount_per_quantity: number;
	net_amount_excl: number;
	tax_rate: number;
	tax_amount: number;
	quantity: number;
	period_contribution_per_quantity?: number;
	// Internally used property for user selection
	selected?: boolean;
}

export interface IPurchase {
	id: Guid;
	service_id: Guid;
	amount_excl: number;
	tax_amount: number;
	amount: number;
	medium_id: Guid;
	purchase_date: Date;
	purchase_status_id: number;
	payment_reference: string;
	small_key: number;
	purchase_plan_id: Guid;
	period_applied: boolean;
	purchase_source_id?: Guid;
	purchase_creation_event_id?: number;
	purchase_lines: IPurchaseLine[];
}

export interface IServiceStateAndAction {
	service_id: Guid;
	service_status: ServiceStatus;
	service_status_category: ServiceStatusCategory;
	collector_id: Guid;
	service_type: ServiceType;
	billing_status: BillingStatus;
	latest_period_end_date: Date;
	suspension_date: Date;
	collection_day: number;
	plan_id: Guid;
	mediums: IPaymentMedium[];
	must_pay_info: IMustPayInfo;
	can_buy_info: ICanBuyInfo;
	last_purchase_is_pending: boolean;
	on_network_date?: Date;
}

export interface IMustPayInfo {
	must_pay: boolean;
	arrears_purchase: IPurchase;
}

export interface ICanBuyInfo {
	can_buy: boolean;
	message: string;
	is_purchase_cap_applicable: boolean;
	day_bundle_days_left_in_upfront_purchase_cap: number;
	day_bundle_prices: IProductPricing[];
	day_bundle_month_left_in_upfront_purchase_cap: number;
	recurring_monthly_price: IProductPricing;
  recurring_product_price_per_day: IProductPricePerDay;
	recurring_month_left_in_upfront_purchase_cap: number
}

export interface IProductPricePerDay {
	product_price_per_day_inclusive: number;
	product_price_per_day_exclusive: number;
	product_price_per_day_tax: number;
	product_tax_rate: number;
}

export interface IPurchaseOrderRequest {
	purchase_id?: string;
	medium_id: string;
	service_id: string;
	process_immediate_purchase: boolean;
	success_url: string;
	failure_url: string;
	purchase_source_id?: string;
	comment?: string;
}

export interface IPurchaseResponse {
	payment_link_url: string;
}

export interface IPlanDetail {
	collection_day: number;
}

export interface IBankAccountDetail {
	customer_id?: string;
	account_holder?: string;
	account_name?: string;
	routing_number?: number;
	bank_name?: string;
	bank_code?: string;
	branch_code?: string;
	account_number?: number;
	initials: string;
	name: string;
	surname: string;
	id_num: string;
	passport_num: string;
	country: string;
	account_type: string;
}

export interface ICreditCardDetail {
	account_holder?: string;
	card_number?: number;
	expiry_month?: number;
	expiry_year?: number;
	cvv?: number;
}

export interface IPaymentMedium {
	medium_detail_id?: string;
	bank_account?: string;
	account_nr_partial?: string;
	medium_type?: string;
	medium_status: string;
	is_linked?: boolean;
}

export interface IMediumAccountResponse {
	external_reference: string;
	customer_id: string;
	medium_detail_id: string;
	medium_type: string;
	medium_status: string;
	created_date: Date;
	account_name: string;
	account_number: string;
}

export interface IPaymentHistory {
	id?: string;
	status: PurchaseStatusCalculate;
	purchase_status_as_text: string;
	amount: string;
	tax_amount: string;
	amount_excl: string;
	purchase_date?: string;
	payment_date?: string;
	medium_type: string;
	tppp_reference: string;
	medium_status: string;
	collection_day?: number;
	result_message: string;
	payments?: IPaymentResponse[];
	splits?: IPurchaseSplitResponse[];
	service_id?: string;
	is_purchase_payment_retry_allowed: boolean;
	is_off_system_payment_allowed: boolean;
	lines?: ILineItem[];
	is_refund: boolean;
	refund_payment_id: string;
}

export interface INextPayment {
	date?: string;
	amount?: number;
	current_expiry?: Date;
}

export interface IServiceChange {
	service_change: IServiceChangeDetails;
}

export interface IServiceChangeDetails {
	service_id: string;
	product_id: string;
}

export interface IWifiInformation {
	ssid: string;
	wifi_enabled: boolean;
	wifi_device_devices: IWifiDevice[];
}

export interface IWifiDevice {
	active: string;
	address_source: string;
	host_name: string;
	ip_address: string;
	layer_two_interface: string;
	lease_time_remaining: string;
	mac_address: string;
	huawei_device_type: string;
	alias: string;
	last_seen: string;
}

export interface IUserRoleResponse {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	items: any[];
	customer_id: string;
	page: number;
	count: number;
	total: number;
}

export interface IWifiDeviceCount {
	device_count: number;
	wifi_enabled: boolean;
}

export interface IAreaInfo {
	name: string;
	isInterest: boolean;
	isPreOrder: boolean;
}

export interface ICol {
	field: string;
	header: string;
}

export interface IDocument {
	id: number;
	filename?: string;
	namespace?: string;
	created_at?: string;
	updated_at?: string;
	size?: string;
	upload_type?: string;
}

export interface IPurchasePlan {
	id: string;
	amount: number;
	end_date: Date;
	is_active: Date;
	medium_detail_id: string;
	payment_day: number;
	recurrence_unit_count: number;
	recurrence_unit_id: number;
	service_id: string;
	start_date: Date;
	last_payment_day_change: Date;
}

export interface IPaymentLinkMediumDetail {
	collector_id: string;
	customer_id: string;
}

export interface IAdvert {
	id: string;
	link: string;
	display: string;
	show_from: Date;
	show_to: Date;
	imgSrc: string;
}

export interface IArea {
	id: string;
	status?: string;
	name: string;
	region_id: number;
	network_type: string;
	area_status?: string | null;
	city?: string;
	province?: string;
	area_type?: string;
	updated_at?: string;
	created_at?: string;
	area_type_name?: string | null;
	facility_id?: string | null;
	has_children?: boolean;
}

export interface IMapComponentInfo extends LatLngLiteral {
	area: IArea;
}

export interface IInterestQuestionsWrapper {
	questions: IInterestQuestion[];
}

export interface IInterestQuestion {
	asset_attribute: string;
	answer: string;
	question: string;
	required: boolean;
	dependant: { [param: string]: string };
	disabled: boolean;
	options: string[];
	isAnswered?: boolean;
}

export interface IQrRedirect {
	serviceUrl: string;
	isOntOnNetwork: boolean;
	serviceGuid: string;
	isOfus: boolean;
	isNetNineNine: boolean;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any,@typescript-eslint/ban-types
export type QuestionAnswers = {}[];

export interface IAnswers {
	attributes: QuestionAnswers;
}

export interface IStripeSetupIntent {
	customer_id: string;
	collector_id: string;
	medium_type: string;
}

export interface IStripeSetupIntentResponse {
	stripe_customer_id: string;
	stripe_client_secret: string;
	medium_detail_id: string;
}

export interface IMediumDetailBaseDto {
	customer_id: string;
	details: string;
	medium_status_id: number;
}

export interface IMediumDetailUpdateDto extends IMediumDetailBaseDto {
	medium_detail_id: string;
}

export interface IMediumDetailInsertDto extends IMediumDetailBaseDto {
	medium_type: string;
}

export interface IMediumDetailDto extends IMediumDetailBaseDto {
	medium_detail_id: string;
	medium_type: string;
	bank_account: string;
	account_nr_partial: string;
	is_linked: boolean;
	medium_status: string;
}

export interface INode {
	id: string;
	network_id: string;
	operator_id: string;
	type: string;
	node_type: string;
	parent_id: string;
	identifier: string;
	status: string;
}

export interface ICountry {
	code: string;
	code3: string;
	name: string;
	number: string;
}

export interface IEnumerable {
	id: number;
	name: string;
}

export class ImageUpload implements IUploadedImage {
	public id: number;
	public guid: string;
	public size: number;
	public progress: number;
	public updated_at: string;
	public docId: string;
	public status: FileUploadStatus = FileUploadStatus.PENDING;

	constructor(public data: string | Blob, public type: string, public filename: string, public namespace?: string) {
		this.guid = Guid.raw();
	}

	public getFormData(includeNamespace: boolean = true): FormData {
		const formData = new FormData();

		formData.append('file[filename]', this.filename);
		if (includeNamespace) formData.append('file[namespace]', this.namespace);
		formData.append('upload', this.data);

		return formData;
	}
}

export interface IUploadedImage {
	id: number;
	guid: string;
	filename: string;
	namespace?: string;
	type: string;
	updated_at: string;
}

export type FileUploadProgress = TSMap<string, Observable<number>>;

export function newFileUploadProgress(): FileUploadProgress {
	return new TSMap<string, Observable<number>>();
}

export class IApplicationUser {
	public userEmail: string;
	public clientUrl: string;
	public userReferralCode: string;
}

export interface IHeaderRoutes {
	name: string;

	path: string;

	show: boolean;

	isExternal: boolean;

	size?: string;

	button?: boolean;

	link?: boolean;
}

export interface ICardCheckout {
	amount: number;
	provider_Id: string;
	medium_Detail_Id: string;
}

export interface ICardResponse {
	provider_Id: string;
	medium_detail_id: string;
	resource_path: string;
}

export interface IMediumTypeProvider {
	provider_id: string;
	medium_type: string;
	provider_code: string;
}

export interface IStripeMediumExists {
	customer_id: string;
	medium_exists: boolean;
}

export interface IConfigurationBase {
	id: number | null;
	name: string;
	description: string;
	parent_id: number | null;
	operator_id: string;
	configuration_type: ConfigurationType;
	configuration_mappings: IConfigurationMapping[];
}

export interface IBillingSchemeConfiguration extends IConfigurationBase {
	settings: IBillingScheme;
}

export interface IConfiguration extends IConfigurationBase {
	version: string;
	json: string;
}

export interface IConfigurationMapping {
	id: number | null;
	configuration_id: number | null;
	configuration_mapping_type: ConfigurationMappingType;
	values: string[];
}

export interface IProductPricePerDayCalculation {
	monthly_price_times_twelve_divided_by_three_sixty_five: boolean;
	on_days_this_month: boolean;
	on_fixed_monthly_days: boolean;
	fixed_monthly_days: number;
}

export interface IOnOrderPurchase {
	allow_deferred: boolean;
	add_installation: boolean;
	add_recurring_monthly_price: boolean;
	days_to_add_to_period_on_recurring_price: number;
	add_day_bundle_bundle_size: number;
}

export interface IOnOrder {
	create_purchase_on_order: boolean;
	require_tokenized_medium: boolean;
	on_order_purchase: IOnOrderPurchase;
}

export interface IOnActivationPurchase {
	on_activation_purchase_created_for: OnActivationPurchaseCreatedFor;
	add_activation_pro_rata: boolean;
	add_installation: boolean;
	add_recurring_monthly_price: boolean;
	allow_pro_rata_up: boolean;
	allow_pro_rata_down: boolean;
}

export interface IOnActivation {
	snap_service_collection_day_to_next_available_collection_day: boolean;
	snap_service_collection_day_to_next_available_collection_day_delay?: number;
	on_activation_purchase: IOnActivationPurchase;
}

export interface IOnPlanPurchaseCreation {
	set_purchase_date_to_next_collection_day_not_fully_covered_by_period: boolean;
	create_zero_rated_purchase_for_next_available_collection_day: boolean;
	stop_plan_purchases_when_in_arrears: boolean;
	duplicate_last_failed_plan_purchase_when_in_arrears: boolean;
	recoup_all_failed_plan_purchases: boolean;
	allow_pro_rata_up: boolean;
	allow_pro_rata_down: boolean;
}

export interface IOnPayNow {
	payment_allowed_if_account_up_to_date: boolean;
	place_cap_on_recurring_purchases_ahead: boolean;
	max_qty_months_purchase_limit: number;
	max_qty_days_purchase_limit: number;
	retry_failed_plan_purchase_when_in_arrears: boolean;
	recurring_failed_purchase_retried_unless_period_in_past: boolean;
	recurring_recoup_all_failed_purchases: boolean;
}

export interface ISuspensionSettings {
	base_suspension_date_on_date_of_purchase_failure: boolean;
	base_suspension_date_on_failed_purchase_date: boolean;
	immediate_suspension: boolean;
	suspend_at_midnight: boolean;
	grace_period_days?: number;
	always_resend_suspension_to_radius: boolean;
	no_valid_medium_suspend: boolean;
}

export interface IMediumTypes {
	allowed_mediums: PaymentType[];
}

export interface IPurchaseReversalBehaviour {
	restrict_purchase_reversal_to_latest_purchase: boolean;
	adjust_subsequent_purchase_periods_down_by_reversed_purchase_period_collapse_void: boolean;
}

export interface IProductPurchaseOrderServiceManagement {
	product_purchase_order_service_managed: boolean;
	remove_pending_payment_work_order_status: boolean;
	installation_status_on_order: string;
	installation_status_when_paid: string;
}

export interface IInvoice {
	show_date_range: boolean;
}

export interface IEmail {
	payment_medium_days: number[];
	bundle_expiry_days: number[];
}

export interface IOffSystemPaymentBehaviour {
	allowed: boolean;
	include_day_prices_for_recurring_in_can_buy: boolean;
}

export interface ICollectionDayChangeSettings {
	allow_collection_day_change: boolean;
	collection_day_change_future_dated_purchase_behaviour: CollectionDayChangeFutureDatedPurchaseBehaviour;
	cap_collection_day_change_frequency: boolean;
	max_collection_day_change_frequency_in_days: number;
}

export interface IRecurrenceScheme {
	allowed_days_type: AllowedDaysType;
	allowed_days: number[];
	debit_run_hour: number;
	collection_day_adjustment_preference: CollectionDayAdjustmentPreference;
	collection_day_change_settings: ICollectionDayChangeSettings;
}

export interface IAutoRechargeScheme {
	auto_recharge: boolean;
	days_before_suspension: number;
}

export interface IPlanCollectionDayChangeResponse {
	new_payment_amount: number;
	new_payment_date: Date;
	pro_rata_amount: number;
	pro_rata_days: number;
	result: string;
}

export interface IBillingScheme {
	service_type: ServiceType;
	split_payment: boolean;
	product_price_per_day_calculation?: IProductPricePerDayCalculation;
	on_order?: IOnOrder;
	on_activation?: IOnActivation;
	on_plan_purchase_creation?: IOnPlanPurchaseCreation;
	on_pay_now?: IOnPayNow;
	recurrence_scheme?: IRecurrenceScheme;
	auto_recharge_scheme?: IAutoRechargeScheme;
	suspension_settings?: ISuspensionSettings;
	medium_types?: IMediumTypes;
	purchase_reversal_behaviour?: IPurchaseReversalBehaviour;
	product_purchase_order_service_management?: IProductPurchaseOrderServiceManagement;
	off_system_payment_behaviour?: IOffSystemPaymentBehaviour;
	invoice?: IInvoice;
	email?: IEmail;
	refund?: IRefund;

	product_price_per_day_calculation_override: boolean;
	on_order_override: boolean;
	on_activation_override: boolean;
	on_plan_purchase_creation_override: boolean;
	on_pay_now_override: boolean;
	recurrence_scheme_override: boolean;
	auto_recharge_scheme_override: boolean;
	suspension_settings_override: boolean;
	medium_types_override: boolean;
	purchase_reversal_behaviour_override: boolean;
	product_purchase_order_service_management_override: boolean;
	off_system_payment_behaviour_override: boolean;
	invoice_override: boolean;
	email_override: boolean;
	refund_override: boolean;
}

export interface ICheckOutResult {
	code: string;
	description: string;
}

export interface ICardCheckOutResponseBase {
	result: ICheckOutResult;
	build_number: string;
	timestamp: Date;
	ndc: string;
	id: string;
	base_url: string;
	registration_id: string;
	payment_type: string;
	payment_brand: string;
	amount: string;
	currency: string;
	merchant_transaction_id: string;
}

export interface IBillingSchemeSection<T> {
	form: FormGroup;
	parent: IBillingSchemeConfiguration;
	formRawValues: T;
	override: boolean;
	isSectionRequired: boolean;
	hasDirtyControl: boolean;
	preSubmitMessages: string[];
}

export interface IBillingSchemeFormConfig {
	[key: string]: {
		name: string;
		tooltip: string;
	};
}

export interface INidInstall {
	service_id: string;
	workOrder_id: string;
	account_id: string;
	type_id: number;
	status_id: string;
}

export interface INidInstallResponse {
	items: INidInstall[];
}

export interface IHeaders {
	name: string;
	descriptions: string;
}

export interface IRicaDocumentWithDescription {
	name: string;
	description: string;
	isUploaded: boolean;
}

export interface IHours {
	id: number;
	text: string;
}

export type Bit = 0 | 1;

export interface IDeferredPaymentType {
	medium_type: PaymentType;
	description: string;
}

export interface IPaymentLinkResponse {
	email: string;
	amount: number;
}

export interface IColumn {
	field: string;
	header: string;
}

export interface IPricingMediumInfo {
	medium_type: string;
	amount: number;
	amount_inc_tax: number;
	transaction_fee: number;
	lines: ILineItem[];
	quantity: number;
}

export interface IDuration {
	field: string;
	header: string;
}

export interface IPurchaseSplitResponse {
	amount: number;
	collector: string;
}

export interface IPortalPaymentHistory {
	id?: string;
	status: PurchaseStatusCalculate;
	purchase_status_as_text: string;
	amount: string;
	purchase_date?: string;
	payment_date?: string;
	duration_period?: IDuration[];
	medium_type: string;
	tppp_reference: string;
	medium_status: string;
	collection_day?: number;
	result_message: string;
	payments?: IPaymentResponse[];
	splits?: IPurchaseSplitResponse[];
	service_id?: string;
	is_purchase_payment_retry_allowed: boolean;
	is_off_system_payment_allowed: boolean;
}

export interface IPurchaseSource {
	id: string;
	name: string;
	offSystem: boolean;
}

export interface IOffSystemPayment {
	purchase_date: Date;
	amount: number;
	comment: string;
	purchase_source_id: string;
	price_selection_override: ILineItem[];
}

export interface IPurchaseDetail {
	description: string;
	amount: number;
	user_name: string;
	comment: string;
	purchase_source: string;
}

export interface IOffSystemPaymentInitialState {
	serviceId?: string;
	purchaseId?: string;
	amount?: number;
	daysPurchased?: number;
	purchaseDate?: Date;
	purchaseLines?: IPurchaseLine[];
	isOnDemandPayment?: boolean;
	mediumId: string;
	isDayBundle: boolean;
	purchaseSources: IPurchaseSource[];
	comment: string;
	enableDateAndAmount: boolean;
}

export interface IPaymentLinkInitialState {
	serviceId?: string;
	purchaseId?: string;
	amount?: number;
	emailAddress: string;
	mobileNumber: string;
	purchaseLines?: IPurchaseLine[];
	mediumId: string;
	isDayBundle: boolean;
	purchaseSource: IPurchaseSource;
}

export interface IRefund {
	offset_allowed: boolean;
	refund_allowed: boolean;
	off_system_refund_allowed: boolean;
}

export interface IRefundDocRequest {
	operator_id: string;
	service_id: string;
	payment_id: string;
	refund_id: string;
}

export interface IRule {
	id: number;
	rule_name: string;
	description: string;
	created_at: Date;
	updated_at: Date;
	deleted: boolean;
	enabled: boolean;
	last_run_date: Date | null;
	next_run_date: Date | null;
	in_progress: boolean;
}

export interface IRuleHistory {
	id: number;
	rule_id: number;
	comment: string;
	created_at: Date;
}

export interface ICondition {
	id: number;
	rule_id: number;
	condition_type_id: number;
	operation_id: number;
	value_id: number;
	logic_operator: string | null;
}

export interface IConditionType {
	id: number;
	type_name: string;
	description: string;
	enabled: boolean;
}

export interface IOperation {
	id: number;
	operator: string;
	data_operator: string;
	enabled: boolean;
}

export interface IConditionValue {
	id: number;
	data_value: string;
	data_part: string | null;
	data_function: string | null;
	domain_field_id: number;
}

export interface IStep {
	id: number;
	rule_id: number;
	step_type_id: number;
	step: number;
}

export interface IStepType {
	id: number;
	type_name: string;
	description: string;
	enabled: boolean;
}

export interface IStepTypeTransition {
	id: number;
	step_id: number;
	from_status: string;
	to_status: string;
	domain_field_id: number;
	transition_reason: string | null;
}

export interface IDomain {
	id: number;
	domain_name: string;
	description: string;
}

export interface IDomainField {
	id: number;
	domain_id: number;
	display_name: string;
	field: string;
	field_data_type: string;
	searchable: boolean;
	external_table: string;
}

export interface IFullRule {
	id: number;
	rule_name: string;
	description: string;
	created_at: Date;
	updated_at: Date;
	deleted: boolean;
	enabled: boolean;
	last_run_date: Date | null;
	conditions: IFullCondition[] | null;
	steps: IFullStep[] | null;
}

export interface IFullCondition {
	id: number;
	rule_id: number;
	condition_type_id: number;
	operation_id: number;
	value_id: number;
	condition_type: IConditionType;
	operation: IOperation;
	condition_value: IFullConditionValue;
	logic_operator: string | null;
}

export interface IFullNewCondition {
	condition: ICondition;
	condition_value: IConditionValue;
}

export interface IFullConditionValue {
	id: number;
	data_value: string;
	data_part: string | null;
	data_function: string | null;
	domain_field_id: number;
	domain_field: IFullDomainField;
}

export interface IFullDomainField {
	id: number;
	domain_id: number;
	display_name: string;
	field: string;
	field_data_type: string;
	searchable: boolean;
	external_table: string;
	domain: IDomain;
}

export interface IFullStep {
	id: number;
	rule_id: number;
	step_type_id: number;
	step_type: IStepType;
	step_transition: IFullStepTypeTransition;
	step: number;
}

export interface IFullStepTypeTransition {
	id: number;
	step_id: number;
	from_status: string;
	to_status: string;
	transition_reason: string | null;
	domain_field_id: number;
	domain_field: IFullDomainField;
}

export type SubTabStatus = 'overview' | 'active' | 'suspended';
export type ChartStatus = 'Operational Status' | 'Service Status';

export interface IFrequency {
	band_width: string;
	channel: string;
	channel_width: string;
	information: string[];
	ssid: string;
	wmm: string;
}

export interface IConfiguredWifi {
	auth_mode: string;
	connection_status: string;
	encryption_mode: string;
	frequency: IFrequency[];
	transport_power: string;
}

export interface IWifiFrequency {
	added: string;
	configured_wifi: IConfiguredWifi;
	device_id: string;
	pppoe_password: string;
	pppoe_username: string;
}
