import { AexNgToolboxModule } from '@aex/ngx-toolbox';
import { AdvertService, AssetService } from '@aex/shared/apis';
import { CommonLibUiComponentsModule } from '@aex/shared/common-lib';
import { PromptInstallService } from '@aex/shared/root-services';
import { CommonModule, NgOptimizedImageModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { ProductComponent } from '@client/components/product.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgbCarouselModule } from '@ng-bootstrap/ng-bootstrap';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { TimepickerModule } from 'ngx-bootstrap/timepicker';
import { NgxUiLoaderModule } from 'ngx-ui-loader';
import { DividerModule } from 'primeng/divider';
import { DropdownModule } from 'primeng/dropdown';
import { InputTextModule } from 'primeng/inputtext';
import { AdvertsComponent } from './components/adverts.component';
import { BankingDetailsComponent } from './components/banking-details/banking-details.component';
import { CreditPurchaseTableComponent } from './components/credit-purchase-table.component';
import { DebitDaysComponent } from './components/debit-days/debit-days.component';
import { FooterComponent } from './components/footer.component';
import { HeaderComponent } from './components/header.component';
import { InterestQuestionComponent } from './components/interest-questions/interest-question.component';
import { InterestQuestionsInFlightComponent } from './components/interest-questions/interest-questions-in-flight.component';
import { InterestQuestionsLazyLoadComponent } from './components/interest-questions/interest-questions-lazy-load.component';
import { InterestQuestionsComponent } from './components/interest-questions/interest-questions.component';
import { OrderCardComponent } from './components/order-card/order-card.component';
import { PlanDetailsComponent } from './components/plan-details/plan-details.component';
import { ProductsViewComponent } from './components/products-view.component/products-view.component';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatAutocompleteModule } from '@angular/material/autocomplete';

@NgModule({
	imports: [
		CommonModule,
		FlexLayoutModule,
		FontAwesomeModule,
		FormsModule,
		NgbCarouselModule,
		NgxUiLoaderModule,
		ReactiveFormsModule,
		RouterModule,
		AexNgToolboxModule.forChild(),
		BsDatepickerModule,
		TimepickerModule,
		DividerModule,
		DropdownModule,
		InputTextModule,
		CommonLibUiComponentsModule,
		MatInputModule,
		MatSelectModule,
		MatAutocompleteModule,
		NgOptimizedImageModule,
	],
	declarations: [
		HeaderComponent,
		FooterComponent,
		InterestQuestionComponent,
		InterestQuestionsComponent,
		InterestQuestionsLazyLoadComponent,
		InterestQuestionsInFlightComponent,
		AdvertsComponent,
		CreditPurchaseTableComponent,
		ProductComponent,
		OrderCardComponent,
		BankingDetailsComponent,
		ProductsViewComponent,
		PlanDetailsComponent,
		DebitDaysComponent,
	],
	providers: [
		AdvertService,
		AssetService,
		PromptInstallService,
	],
	exports: [
		HeaderComponent,
		FooterComponent,
		AdvertsComponent,
		InterestQuestionsLazyLoadComponent,
		InterestQuestionsInFlightComponent,
		CreditPurchaseTableComponent,
		ProductComponent,
		OrderCardComponent,
		BankingDetailsComponent,
		ProductsViewComponent,
		PlanDetailsComponent,
		DebitDaysComponent,
		CommonLibUiComponentsModule,
	],
})
export class SharedComponentsModule { }
